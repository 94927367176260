<template>
  <div>
    <b-container>
      <div id="product" style="position: relative; top: -70px"></div>
      <h1>Produkte</h1>
      <b-row>
        <b-col class="text-center">
          <h3>Organic Regimedy</h3>

          <p>MIT CBD ANGEREICHERT</p>
          <p>
            Eine natürliche, biologische, wasserfreie, parfümfreie, auf Aloe
            basierende, ätherische Öl- und <b>CBD-angereicherte Serie</b>, die
            die Definition von rein, natürlich und biologisch neu definiert,
            wenn es um das Wohlbefinden Ihrer Haare geht.
          </p>
          <p>
            Wir haben verschiedene Plantagen besucht, um die reichhaltigsten und
            reinsten Pflanzen, die es gibt, für diese einzigarte Serie zu
            beschaffen. Wir haben maximalen Wert darauf gelegt, mit
            akkreditierten Labors zusammen zu arbeiten, die für die Herstellung
            von dem <b>REINSTEN CBD Öl</b> zertifiziert sind. Wasser wurde durch
            Aloe als Basis ersetzt.
          </p>
          <p>
            Diese Produkte sind mit ätherischen Ölen, Mineralien, Ionen und
            Aminosäuren gefüllt und enthalten alle Nährstoffe, die unser Haar
            benötigt.
          </p>
          Die <b>ORGANIC</b> Serie ist Bio zertifiziert.
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img
            class="product-img"
            src="@/assets/img/Slider-ORGANIC.png"
            alt=""
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <h3>Detox Regimedy</h3>
          <p>GESUNDES HAAR FÄNGT MIT EINER GESUNDEN KOPFHAUT AN</p>
          <p>
            <b>Detox</b> eliminiert Toxine durch die Verwendung einer Mischung
            aus Bio-Ölen, die die Durchblutung anregen und so eine gesunde
            Kopfhaut und damit ein gesundes Haarwachstum fördern. Detox bereitet
            Ihr Haar und Ihre Kopfhaut auf andere Haarpflegebehandlungen vor.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img class="product-img" src="@/assets/img/Slider-DETOX.png" alt="" />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <h3>India</h3>
          <p>HAIR-YURVEDICS FÜR GEIST UND INNERLICHE STÄRKE</p>
          <p>
            <b
              >Exotischer Luxus. Basierend auf den Heilpraktiken von Ayurveda
              und ätherischen Ölen.</b
            >
          </p>
          <p>
            I.C.O.N. prägte den Ausdruck Haar-Yurveda. India Produkte pflegen
            das Haar mit den Vorteilen von besonderen Ölen und stärkt Ihr
            Wohlbefinden. Moringa ist ein absolutes „Wunderöl“, welches für
            seine Fettsäuren bekannt ist. Sie stärken und stellen die Vitalität
            des Haares wieder her. Argan, auch als flüssiges Gold bezeichnet,
            pflegt und befeuchtet das Haar, und verhindert Haarausfall. Der
            wunderschöne Duft von Bernstein beruhigt unsere Sinne und erhöht
            unsere Energie.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img class="product-img" src="@/assets/img/Slider-INDIA.png" alt="" />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <h3>Stained Glass</h3>
          <p>NATÜRLICH. REIN. EINZIGARTIG</p>
          <p>
            <b>Stained Glass</b> ist eine natürliche Semi-Haarfarbe, welche
            Ihren Farbton intensiviert oder einen ergänzenden Ton hinzufügt.
            Diese reflektierende Lebensmittelfarbe gibt dem Haar eine seidige
            Textur mit einem ultra-glänzenden Schimmer und bringt eine neue
            Dimension ins Haar.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <img
            class="product-img"
            src="@/assets/img/Slider-STAINED_GLASS.png"
            alt=""
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped>
.product-img {
  max-width: 100%;
  padding: 20px 0px;
}

#product-logo {
  width: 30%;
}

@media (min-height: 821px) {
  #product-logo {
    width: 40%;
  }
}

#product-me {
  font-size: 17px;
}

@media (min-height: 821px) {
  #product-me {
    font-size: 19px;
  }
}
</style>
