<template>
  <div>
    <b-container>
      <div id="service" style="position: relative; top: -70px"></div>
      <h1>Leistungen</h1>
      <b-row style="align-items: center">
        <b-col cols="12" lg="7">
          <img src="@/assets/img/deco.jpg" id="deco-img" />
        </b-col>
        <b-col cols="12" lg="5">
          <b-table-simple id="tableService">
            <b-thead>
              <tr>
                <th colspan="3">Leistungen : Für Damen und Herren</th>
              </tr>
            </b-thead>
            <b-tbody>
              <tr>
                <td>Schneiden:</td>
                <td>ab</td>
                <td>32,- €</td>
              </tr>
              <tr>
                <td>Schneiden mit waschen:</td>
                <td>ab</td>
                <td>35,- €</td>
              </tr>
              <tr>
                <td>Föhnen:</td>
                <td>ab</td>
                <td>30,- €</td>
              </tr>
              <tr>
                <td>Färben:</td>
                <td>ab</td>
                <td>45,- €</td>
              </tr>
              <tr>
                <td>Strähnen:</td>
                <td>ab</td>
                <td>30,- €</td>
              </tr>
              <tr>
                <td>Dauerwelle:</td>
                <td>ab</td>
                <td>50,- €</td>
              </tr>
              <tr>
                <td>Pflege:</td>
                <td>ab</td>
                <td>5,- €</td>
              </tr>
              <tr>
                <td>Augenbrauen Färben</td>
                <td>ab</td>
                <td>6,- €</td>
              </tr>
              <tr>
                <td>Augenbrauen Zupfen</td>
                <td>ab</td>
                <td>5,- €</td>
              </tr>
              <tr>
                <td>Wimpern Färben ab</td>
                <td>ab</td>
                <td>12,- €</td>
              </tr>
              <tr>
                <td>Kinderschnitt</td>
                <td>ab</td>
                <td>18,- €</td>
              </tr>
              <tr>
                <td colspan="3">Steck u. Flecht Frisuren 1.- die Minute</td>
              </tr>
              <tr>
                <td colspan="3">Hausbesuche nach Vereinbarung</td>
              </tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  components: {},
}
</script>

<style scoped>
h1 {
  color: #fff;
}

#tableService td:nth-child(3) {
  text-align: right;
}

#tableService td:nth-child(3) {
  min-width: 65px;
}

#tableService {
  color: #fff;
  margin-top: 40px;
  margin-bottom: 40px;
}

#deco-img {
  width: 100%;
  margin-left: 0px;
}

@media (min-width: 992px) {
  #tableService {
    margin-top: 0px;
  }

  #deco-img {
    margin-left: -50px;
  }
}
</style>
