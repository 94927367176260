import Vue from 'vue'
import App from './App.vue'

import AxiosPlugin from 'vue-axios-cors';
Vue.use(AxiosPlugin)

// run "npm install smoothscroll-polyfill" before
import smoothscroll from 'smoothscroll-polyfill/dist/smoothscroll' // 'smoothscroll-polyfill'
smoothscroll.polyfill()

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// layout
import './assets/layout.css'

Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
}).$mount('#app')
