<template>
  <div style="position: relative">
    <div id="contact" style="position: relative; top: -70px"></div>

    <b-container>
      <h1>Kontakt</h1>
      <b-row>
        <!-- data table -->
        <b-col cols="12" lg="6" id="contactData">
          <b-row>
            <b-col cols="2" md="1">
              <b-icon icon="geo-alt"> </b-icon>
            </b-col>
            <b-col class="mb-3">
              <h4>Anschrift</h4>
              <p>
                FrisurenKunst<br />
                Elina Vorderer<br />
                Löwenberger Str. 65<br />
                90475 Nürnberg
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2" md="1"><b-icon icon="phone"></b-icon></b-col>
            <b-col class="mb-3">
              <h4>Telefon</h4>
              <p>01520 653 88 24</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2" md="1">
              <b-icon icon="clock"></b-icon>
            </b-col>
            <b-col>
              <h4>Öffnungszeiten</h4>
              <p>
                Mo-Di & Do-Fr 9-18Uhr<br />
                Samstags Termine nach Vereinbarung
              </p>
            </b-col>
          </b-row>
        </b-col>
        <!-- mail form -->
        <b-col cols="12" lg="6">
          <b-form @submit="onSubmit">
            <b-form-group label-for="input-1" label="Dein Name">
              <b-form-input
                id="input-1"
                v-model="form.name"
                placeholder="Dein Name"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="E-Mail" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.email"
                placeholder="E-Mail Adresse"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Telefon" label-for="input-3">
              <b-form-input
                id="input-3"
                v-model="form.phone"
                placeholder="Telefonnummer"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Ihre Nachricht" label-for="input-4">
              <b-form-textarea
                id="input-4"
                v-model="form.message"
                placeholder="Ihre Nachricht..."
                rows="5"
                max-rows="8"
                required
              ></b-form-textarea>
            </b-form-group>

            <div style="position: relative">
              <b-button id="send-btn" variant="success" type="submit"
                >Abschicken</b-button
              >

              <div id="alert">
                <b-alert :show="showMessage" fade variant="info">
                  Ihre Mail wurde versendet. Vielen Dank.
                </b-alert>
              </div>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
    <div id="stuff">
      <!-- datenschutz -->
      <span style="cursor: pointer" @click="$bvModal.show('ds-modal')"
        >Datenschutz</span
      >

      <b-modal id="ds-modal" size="xl" scrollable ok-only>
        <template #modal-header
          ><h1 style="margin-bottom: -19px">Datenschutzerklärung</h1></template
        >
        <datenschutz-me />
      </b-modal>

      <span class="mx-5">|</span>

      <!-- impressum -->
      <span style="cursor: pointer" @click="$bvModal.show('imp-modal')"
        >Impressum</span
      >

      <b-modal id="imp-modal" size="xl" scrollable ok-only>
        <template #modal-header
          ><h1 style="margin-bottom: -19px">Impressum</h1></template
        >
        <impressum-me />
      </b-modal>
    </div>
  </div>
</template>

<script>
import DatenschutzMe from "./DatenschutzMe.vue";
import ImpressumMe from "./ImpressumMe.vue";

export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      showMessage: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    onSubmit(e) {
      e.preventDefault();

      const options = {
        url: process.env.VUE_APP_HOME + "mail.php",
        method: "POST",
        data: this.form,
      };

      this.$axios(options)
        .then((res) => {
          console.log("Mail send. ", res.data);
          // message
          this.showMessage = true;
          setTimeout(() => {
            this.showMessage = false;
          }, 3000);
          // clear form
          this.form.name = "";
          this.form.email = "";
          this.form.phone = "";
          this.form.message = "";
        })
        .catch((err) => {
          console.error("Mail sending failed. ", err);
        });
    },
  },

  components: {
    DatenschutzMe,
    ImpressumMe,
  },
};
</script>

<style scoped>
#contactData .b-icon {
  font-size: 36px;
  color: #999;
}

#contactData {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 30px 0 60px 0;
}

@media (min-width: 991px) {
  #contactData {
    margin: 60px 0 120px 0;
  }

  .form-group >>> label {
    font-size: 24px;
  }

  .form-group >>> input,
  .form-group >>> textarea {
    font-size: 18px;
    padding: 15px 15px;
  }
}

.form-group {
  margin-bottom: 15px;
}

.form-group >>> label {
  font-size: 22px;
  margin-bottom: 5px;
  color: #999;
}

.form-group >>> input,
.form-group >>> textarea {
  font-size: 16px;
  padding: 10px 10px;
}

#stuff {
  position: absolute;
  bottom: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (min-height: 821px) {
  .form-group {
    margin-bottom: 25px;
  }

  .form-group >>> label {
    font-size: 24px;
  }

  .form-group >>> input,
  .form-group >>> textarea {
    font-size: 18px;
    padding: 15px 15px;
  }
}

#send-btn {
  background-color: var(--maincolor) !important;
  margin-bottom: 65px;
}

#alert {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 136px;
}

#alert div {
  font-size: 24px;
  font-weight: bold;
  width: 350px;
  margin: 0 auto;
  text-align: center;
}
</style>
