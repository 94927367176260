<template>
  <b-navbar
    :type="barType"
    toggleable="lg"
    fixed="top"
    id="menu"
    :style="{ backgroundColor: menuColor }"
  >
    <b-container :class="{ 'nav-dark': firstPageCollapse }">
      <b-navbar-brand @click.prevent="clickElina">Elina</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item style="color: #fff" @click.prevent="scrollTo('#overme')"
            >Über mich</b-nav-item
          >
          <b-nav-item href="#" @click.prevent="scrollTo('#product')"
            >Produkte</b-nav-item
          >
          <b-nav-item @click.prevent="scrollTo('#service')"
            >Leistungen</b-nav-item
          >
          <b-nav-item @click.prevent="scrollTo('#contact')">Kontakt</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      scrollPosition: 0,
      firstPageCollapse: false,
      isShow: false,
    }
  },
  created() {},
  computed: {
    menuColor() {
      let color = getComputedStyle(document.documentElement)
        .getPropertyValue('--maincolor')
        .trim()
        .slice(5, -1)
        .split(',')

      return (
        'rgba(' +
        color[0] +
        ', ' +
        color[1] +
        ', ' +
        color[2] +
        ', ' +
        (this.scrollPosition + 70) / window.innerHeight +
        ')'
      )
    },
    barType() {
      return this.scrollPosition + 70 < window.innerHeight ? 'dark' : 'light'
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
    // navbar collapse
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      this.isShow = isJustShown
      this.setFirstPageCollapse(isJustShown)
    })
  },
  watch: {},
  methods: {
    // current scroll position
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    scrollTo(selector) {
      document.querySelector(selector).scrollIntoView({ behavior: 'smooth' })
    },
    setFirstPageCollapse(show) {
      this.firstPageCollapse = show
    },
    clickElina() {
      this.scrollTo('#start')
      if (this.isShow) this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
    },
  },
  beforeUnmount() {},
  components: {},
}
</script>

<style scoped>
#menu {
  font-family: 'Aboreto', sans-serif;
  padding: 0;
  background-color: var(--maincolor);
}

#menu .navbar-brand {
  font-size: 40px;
  cursor: pointer;
  /* color: #fff; */
}

#menu .nav-item {
  font-size: 24px;
  padding-right: 25px;
}

.nav-dark {
  background-color: var(--maincolor);
}

@media (min-width: 992px) {
  #menu .container {
    justify-content: center;
  }

  #menu .container .navbar-collapse {
    flex-grow: initial;
  }
}
</style>
