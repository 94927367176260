<template>
  <div v-if="showInfo" id="info-me">
    <h3>
      News
      <b-icon
        id="info-close"
        icon="x"
        animation="throb"
        font-scale="1"
        @click="showInfo = false"
      >
      </b-icon>
    </h3>
    Bla bla.... hjkdhfkahdkjfhahsdkfa fkajsh flkh ajksf
    <b>fasdfasdfasdf</b>
    asdfasdfa
  </div>
</template>

<script>
export default {
  data() {
    return {
      showInfo: false,
    };
  },
  created() {
    let date1 = new Date();
    let date2 = new Date("2022.11.18");

    if (date1 <= date2) this.showInfo = true;
  },
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped>
#info-me {
  position: absolute;
  top: 100px;
  left: 25px;
  background-color: #a8ffff;
  padding: 12px;
  border-radius: 5px;
  max-width: 300px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #fff;
}

#info-me h3 {
  display: flex;
  justify-content: space-between;
}

#info-close:hover {
  cursor: pointer;
}
</style>
