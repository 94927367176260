<template>
  <div style="position: relative">
    <div id="imageBox" style="height: 100%"></div>
    <div id="imageTitle" :style="{ fontSize: titleSize }">
      <div>Seien sie</div>
      <div>willkommen</div>
      <div>in der Frisurenkunst</div>
    </div>
    <info-me></info-me>
  </div>
</template>

<script>
import InfoMe from '@/components/InfoMe.vue'

export default {
  data() {
    return {
      imageHeight: 0,
      titleSize: 0,
    }
  },
  created() {
    window.addEventListener('resize', this.resizeImage)
    // change table height
    this.resizeImage()
  },
  computed: {},
  methods: {
    resizeImage() {
      this.imageHeight = window.innerHeight + 'px'
      this.titleSize = window.innerWidth / 18 + 'px'
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeImage)
  },
  components: {
    InfoMe
  },
}
</script>

<style scoped>
#imageBox {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* background-image: url('@/assets/img/elina_big_02.png'); */
  background-image: url('../assets/img/start.jpg');
  /* transform: scaleX(-1); */
}

#imageTitle {
  font-family: 'Aboreto', sans-serif;
  color: var(--maincolor);
  text-shadow: 1px 1px 1px #fff;
  position: absolute;
  bottom: 50px;
  left: 50px;
}
</style>
