<template>
  <div>
    <!-- menu -->
    <menu-me></menu-me>
    <!-- icons -->
    <div id="socialIcons" :class="iconColorBreak ? 'icon-light' : 'icon-dark'">
      <b-icon class="icon" icon="instagram" @click="openInstagram"></b-icon>
      <b-icon class="icon" icon="whatsapp" @click="openWhatsapp"></b-icon>
    </div>
    <!-- screens -->
    <start-me id="start" :style="{ height: startPageHeight }"> </start-me>
    <over-me
      style="background-color: #e5e5e5"
      :style="{ minHeight: pageHeight }"
    ></over-me>
    <product-me
      style="background-color: #01d3f50d"
      :style="{ minHeight: pageHeight }"
    ></product-me>
    <service-me
      style="background-color: #4e4e4e"
      :style="{ minHeight: pageHeight }"
    ></service-me>
    <contact-me
      style="background-color: #e5e5e5"
      :style="{ minHeight: pageHeight }"
    >
    </contact-me>

    <b-alert
      v-model="showButton"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000"
      variant="primary"
    >
      <span style="font-size: 18px; font-weight: bold">
        Mit dem Klick auf die "OK" Schaltfläche stimmen Sie unseren
        Datenschutzbestimmungen dieser Webseite zu.
      </span>
      <span style="float: right">
        <b-button
          variant="outline-primary"
          size="sm"
          @click="showButton = false"
          >OK</b-button
        >
      </span>
    </b-alert>
  </div>
</template>

<script>
import MenuMe from "./components/MenuMe.vue";
import StartMe from "./components/StartMe.vue";
import OverMe from "./components/OverMe.vue";
import ProductMe from "./components/ProductMe.vue";
import ServiceMe from "./components/ServiceMe.vue";
import ContactMe from "./components/ContactMe.vue";

export default {
  data() {
    return {
      startPageHeight: "0px",
      pageHeight: "0px",
      iconColorBreak: true,

      showButton: false,
    };
  },
  created() {
    window.addEventListener("resize", this.resizeImage);
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    this.resizeImage();

    this.showButton = true;
  },
  methods: {
    resizeImage() {
      this.startPageHeight = window.innerHeight + "px";
      this.pageHeight = window.innerHeight - 70 + "px";
      // change picture size
      setTimeout(() => {
        var pic1 = document.getElementById("overme-img");
        var pic2 = document.getElementById("product-img");
        var win = window.innerHeight - 70 - 123 - 50;

        if (pic1.offsetHeight > win)
          pic1.setAttribute("style", "height: " + win + "px");
        else pic1.style.removeProperty("height");

        if (pic2.offsetHeight > win)
          pic2.setAttribute("style", "height: " + win + "px");
        else pic2.style.removeProperty("height");
      }, 300);
    },
    updateScroll() {
      this.iconColorBreak = window.scrollY + 70 < window.innerHeight;
    },
    openInstagram() {
      window.open("https://www.instagram.com/frisurenkunstelina/");
    },
    openWhatsapp() {
      window.open("https://wa.me/49015206538824");
    },
  },
  components: {
    MenuMe,
    StartMe,
    OverMe,
    ProductMe,
    ServiceMe,
    ContactMe,
  },
};
</script>

<style scoped>
/* icons */
#socialIcons {
  position: fixed;
  font-size: 60px;
  width: 60px;
  top: 150px;
  right: 30px;
  z-index: 1;
}

.icon {
  transition: all 0.5s;
}

.icon:hover {
  transform: scale(1.2);
}

.icon-light {
  color: rgba(255, 255, 255, 0.55);
}

.icon-dark {
  color: var(--maincolor);
}

.icon-light .icon:hover {
  color: rgba(255, 255, 255, 0.75);
}

@media (max-width: 991px) {
  #socialIcons {
    font-size: 30px;
    width: 30px;
    right: 15px;
  }
}
</style>
