<template>
  <div>
    <b-container>
      <div id="overme" style="position: relative; top: -70px"></div>
      <h1>Über mich</h1>
      <b-row>
        <b-col cols="12" lg="6">
          <img id="overme-img" src="../assets/img/elina_small.png" />
        </b-col>
        <b-col cols="12" lg="6" id="over-me">
          <p>Seien Sie willkommen in der FrisurenKunst.</p>
          <p>
            Nach über 25 Jahren Erfahrung ist mein Beruf zur Berufung geworden.
          </p>
          <p>
            Sich schön zu machen, ist nicht mehr nur eine Frage der Erscheinung,
            sondern des Gefühls.
          </p>
          <p>
            Sie erwartet eine maßgeschneiderte Beratung und ein offenes Ohr für
            Wünsche und Gedanken.
          </p>
          <p>
            Ich lade Sie zu einem Besuch ein. Lehnen Sie sich zurück und erholen
            Sie sich vom Alltag.
          </p>
          <p>
            Genießen Sie die Zeit und gönnen Sie sich Wellness für Haare und
            Seele.
          </p>
          <p>
            Dazu ist nur ein Schritt nötig. Trauen Sie sich und kommen Sie
            vorbei.
          </p>
          <p>Ich freue mich auf Sie.</p>
          <p></p>
          <p>Ihre Elina</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
  components: {},
}
</script>

<style scoped>
#overme-img {
  max-height: 100%;
  max-width: 100%;
  margin-bottom: 25px;
}

#over-me {
  font-size: 18px;
}

@media (min-height: 821px) {
  #over-me {
    font-size: 20px;
  }
}
</style>
